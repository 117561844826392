// NavigationMenu.js

import React from 'react';

const NavigationMenu = () => {
  return (
    <nav>
      <ul>
        <li><a href="#">Home</a></li>
        <li><a href="#">News</a></li>
        <li><a href="#">Sport</a></li>
        <li><a href="#">Weather</a></li>
        <li><a href="#">iPlayer</a></li>
        <li><a href="#">More</a></li>
      </ul>
    </nav>
  );
};

export default NavigationMenu;
