// App.js

import React from 'react';
import './App.css';
import NavigationMenu from './components/NavigationMenu';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>BBC.co.uk</h1>
        <NavigationMenu />
      </header>
    </div>
  );
}

export default App;
